import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91id_93sMo6H76UlQMeta } from "/opt/buildhome/repo/pages/property/[id].vue?macro=true";
import { default as indexqpuTXRQ6aRMeta } from "/opt/buildhome/repo/pages/property/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "property-id",
    path: "/property/:id()",
    component: () => import("/opt/buildhome/repo/pages/property/[id].vue").then(m => m.default || m)
  },
  {
    name: "property",
    path: "/property",
    component: () => import("/opt/buildhome/repo/pages/property/index.vue").then(m => m.default || m)
  }
]